// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import getDashboardTheme from './components/theme/getDashboardTheme';
import Users from './pages/users/UsersPage';
import Lookup from './pages/lookup/LookupPage';
import Feedback from './pages/feedback/FeedbackPage';
import Notifications from './pages/notifications/NotificationsPage';
import Performance from './pages/performance/PerformancePage';
import Monitoring from './pages/monitoring/MonitoringPage';
import Login from './pages/login/login';
import DashboardLayout from './AdminPanelLayout';

export default function App() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const dashboardTheme = createTheme(getDashboardTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <Router>
      <ThemeProvider theme={showCustomTheme ? dashboardTheme : defaultTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />

          {/* Dashboard routes with shared layout */}
          <Route
            element={
              <DashboardLayout
                mode={mode}
                toggleColorMode={toggleColorMode}
                showCustomTheme={showCustomTheme}
                toggleCustomTheme={toggleCustomTheme}
              />
            }
          >
            <Route path="/" element={<Notifications />} />
            <Route path="/users" element={<Users />} />
            <Route path="/lookup" element={<Lookup />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/performance" element={<Performance />} />
            <Route path="/monitoring" element={<Monitoring />} />

            {/* Route to handle DoctorID and ConsultationID */}
            <Route path="/monitoring/:DoctorID/:ConsultationID" element={<Monitoring />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </Router>
  );
}
