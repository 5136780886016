// FeedbackPage.js (with metrics update)

import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomizedDataGrid from './components/FeedbackTable';
import StatCard from './components/RatingsMetricsCards';
import useAuth from '../../useAuth';
import CircularProgress from '@mui/material/CircularProgress';

export default function MainGrid() {
  useAuth();
  const [metricsData, setMetricsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://g2qfbf14j9.execute-api.af-south-1.amazonaws.com/nora/feedback')
      .then((response) => response.json())
      .then((data) => {
        // Calculate metrics here based on the fetched data
        const totalRatings = data.length;
        const averageRating = data.reduce((acc, curr) => acc + (curr.rating || 0), 0) / totalRatings || 0;
        const positiveRatings = data.filter((item) => (item.rating || 0) >= 4).length;
        const negativeRatings = data.filter((item) => (item.rating || 0) <= 2).length;
        const positivePercentage = ((positiveRatings / totalRatings) * 100).toFixed(2);
        const negativePercentage = ((negativeRatings / totalRatings) * 100).toFixed(2);

        setMetricsData([
          {
            title: 'Total Ratings',
            value: totalRatings.toString(),
            interval: 'All time',
            trend: 'up',
            data: [], // Add data if needed for charts
          },
          {
            title: 'Average Rating',
            value: averageRating.toFixed(2),
            interval: 'All time',
            trend: 'neutral',
            data: [],
          },
          {
            title: '% of Positive Ratings',
            value: `${positivePercentage}%`,
            interval: 'All time',
            trend: 'up',
            data: [],
          },
          {
            title: '% of Negative Ratings',
            value: `${negativePercentage}%`,
            interval: 'All time',
            trend: 'down',
            data: [],
          },
        ]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching metrics data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2, mt: { xs: 6, md: 2 } }}>
        Feedback Overview
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
      ) : (
        <Grid
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          {metricsData.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} lg={3}>
              <StatCard {...card} />
            </Grid>
          ))}
          {/* Include charts if needed */}
        </Grid>
      )}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Feedback Table
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12}>
          <CustomizedDataGrid />
        </Grid>
      </Grid>
      <Box sx={{ my: 4 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          {'© '}
          {new Date().getFullYear()}
          {' Your Company'}
        </Typography>
      </Box>
    </Box>
  );
}
