// MonitoringPage.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StatCard from './components/MonitoringMetricCards';
import ChartUserByCountry from './components/TemplateUsage';
//import SessionsChart from './components/ExecutionTimeVsAudioLengthLineChart';
import useAuth from '../../useAuth';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import MenuButton from '../../components/MenuButton';
import CustomizedDataGrid from './components/MainTable';
import { Card, CardContent } from '@mui/material';

export default function MonitoringPage() {
  useAuth();

  // Extract DoctorID and ConsultationID from URL
  const { DoctorID, ConsultationID } = useParams();

  // State to store API data
  const [apiData, setApiData] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  // State for metrics and raw_data
  const [metrics, setMetrics] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data when DoctorID and ConsultationID are present
  useEffect(() => {
    if (DoctorID && ConsultationID) {
      setApiLoading(true);
      axios
        .get('https://g2qfbf14j9.execute-api.af-south-1.amazonaws.com/nora/status', {
          params: {
            DoctorID,
            ConsultationID,
          },
          headers: {
            'x-api-key': `${process.env.REACT_APP_API_KEY}`,
          },
        })
        .then((response) => {
          console.log('API Response:', response.data);
          setApiData(response.data);
          setApiLoading(false);
        })
        .catch((error) => {
          console.error('API Error:', error);
          setApiError(error);
          setApiLoading(false);
        });
    } else {
      // Fetch the metrics and raw_data
      setLoading(true);
      axios
        .get('https://g2qfbf14j9.execute-api.af-south-1.amazonaws.com/nora/monitor', {
          headers: {
            'x-api-key': `${process.env.REACT_APP_API_KEY}`,
          },
        })
        .then((response) => {
          console.log('API Response:', response.data);
          setMetrics(response.data.metrics);
          setRawData(response.data.raw_data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('API Error:', error);
          setError(error);
          setLoading(false);
        });
    }
  }, [DoctorID, ConsultationID]);

  // Helper function to round values to 2 decimal places
  const roundValue = (value) => {
    if (typeof value === 'number') {
      return Math.round(value * 100) / 100;
    }
    return value;
  };

  // Define metric categories
  const metricCategories = {
    'Consultation Metrics': [
      'Consultations today',
      'Consultations failed',
      'Consultations successful',
      'Consultations errored',
      'Success rate',
      'Consultation correction success rate',
      'Regenerations',
      'Average regenerations per consultation',
    ],
    'Audio Metrics': [
      'Total audio length',
      'Mean audio length',
      'Execution time per minute of audio',
    ],
    'Token Metrics': [
      'Input tokens total',
      'Output tokens total',
      'Mean input tokens',
      'Mean output tokens',
    ],
    'OpenAI Metrics': [
      'Total OpenAI requests',
      'Total OpenAI seconds',
    ],
    'Template Usage Metrics': [
      'Number of times template 1 was used',
      'Number of times template 2 was used',
      'Number of times template 3 was used',
      'Number of times template 4 was used',
    ],
    'Transcription Time Metrics': [
      'Mean time to transcribe',
      'Mean transcription time %',
      'Mean transcription time % (northcentralus)',
      'Mean transcription time % (norwayeast)',
      'Mean transcription time % (southindia)',
      'Mean transcription time % (swedencentral)',
      'Mean transcription time % (westeurope)',
      'Mean transcription time % (OpenAI)',
    ],
    'Generation Time Metrics': [
      'Mean generation time',
      'Mean generation time %',
      'Mean generation time % (southcentralus)',
      'Mean generation time % (swedencentral)',
      'Mean generation time % (eastus)',
      'Mean generation time % (westus)',
      'Mean generation time % (westus5)',
    ],
    'DynamoDB Metrics': [
      'DynamoDB reads used',
    ],
  };

  // Build the monitoring data
  const monitoringData = metrics
    ? [
        {
          title: 'Consultations today',
          value: roundValue(metrics.consultations_today) ?? '-',
        },
        {
          title: 'Consultations failed',
          value: roundValue(metrics.consultations_failed) ?? '-',
        },
        {
          title: 'Consultations successful',
          value: roundValue(metrics.consultations_successful) ?? '-',
        },
        {
          title: 'Consultations errored',
          value: roundValue(metrics.consultations_errored) ?? '-',
        },
        {
          title: 'Success rate',
          value:
            metrics.consultation_success_rate !== undefined
              ? `${roundValue(metrics.consultation_success_rate)}%`
              : '-',
        },
        {
          title: 'Consultation correction success rate',
          value:
            metrics.consultation_correction_success_rate !== undefined
              ? `${roundValue(metrics.consultation_correction_success_rate)}%`
              : '-',
        },
        {
          title: 'Regenerations',
          value: roundValue(metrics.consultations_regen_count) ?? '-',
        },
        {
          title: 'Average regenerations per consultation',
          value: roundValue(metrics.average_regens) ?? '-',
        },
        {
          title: 'Total audio length',
          value: `${roundValue(metrics.total_audio_length) ?? '-'} seconds`,
        },
        {
          title: 'Mean audio length',
          value: `${roundValue(metrics.mean_audio_length) ?? '-'} seconds`,
        },
        {
          title: 'Mean time to transcribe',
          value: `${roundValue(metrics.mean_time_to_transcribe) ?? '-'} seconds`,
        },
        {
          title: 'Mean generation time',
          value: `${roundValue(metrics.mean_generation_time) ?? '-'} seconds`,
        },
        {
          title: 'Execution time per minute of audio',
          value:
            metrics.execution_time_per_min_audio !== undefined
              ? `${roundValue(metrics.execution_time_per_min_audio * 100)}%`
              : '-',
        },
        {
          title: 'Input tokens total',
          value: roundValue(metrics.input_tokens_total) ?? '-',
        },
        {
          title: 'Output tokens total',
          value: roundValue(metrics.output_tokens_total) ?? '-',
        },
        {
          title: 'Mean input tokens',
          value: roundValue(metrics.mean_input_tokens) ?? '-',
        },
        {
          title: 'Mean output tokens',
          value: roundValue(metrics.mean_output_tokens) ?? '-',
        },
        {
          title: 'Total OpenAI requests',
          value: roundValue(metrics.openai_total_requests) ?? '-',
        },
        {
          title: 'Total OpenAI seconds',
          value: roundValue(metrics.openai_total_seconds) ?? '-',
        },
        {
          title: 'Number of times template 1 was used',
          value: roundValue(metrics.total_template_one) ?? '-',
        },
        {
          title: 'Number of times template 2 was used',
          value: roundValue(metrics.total_template_two) ?? '-',
        },
        {
          title: 'Number of times template 3 was used',
          value: roundValue(metrics.total_template_three) ?? '-',
        },
        {
          title: 'Number of times template 4 was used',
          value: roundValue(metrics.total_template_four) ?? '-',
        },
        {
          title: 'Mean transcription time %',
          value:
            metrics.mean_time_to_transcribe_percentage !== undefined
              ? `${roundValue(metrics.mean_time_to_transcribe_percentage * 100)}%`
              : '-',
        },
        {
          title: 'Mean generation time %',
          value:
            metrics.mean_time_to_generate_percentage !== undefined
              ? `${roundValue(metrics.mean_time_to_generate_percentage * 100)}%`
              : '-',
        },
        {
          title: 'Mean transcription time % (northcentralus)',
          value:
            metrics.regional_transcription_metrics.northcentralus !== undefined
              ? `${roundValue(metrics.regional_transcription_metrics.northcentralus * 100)}%`
              : '-',
        },
        {
          title: 'Mean transcription time % (norwayeast)',
          value:
            metrics.regional_transcription_metrics.norwayeast !== undefined
              ? `${roundValue(metrics.regional_transcription_metrics.norwayeast * 100)}%`
              : '-',
        },
        {
          title: 'Mean transcription time % (southindia)',
          value:
            metrics.regional_transcription_metrics.southindia !== undefined
              ? `${roundValue(metrics.regional_transcription_metrics.southindia * 100)}%`
              : '-',
        },
        {
          title: 'Mean transcription time % (swedencentral)',
          value:
            metrics.regional_transcription_metrics.swedencentral !== undefined
              ? `${roundValue(metrics.regional_transcription_metrics.swedencentral * 100)}%`
              : '-',
        },
        {
          title: 'Mean transcription time % (westeurope)',
          value:
            metrics.regional_transcription_metrics.westeurope !== undefined
              ? `${roundValue(metrics.regional_transcription_metrics.westeurope * 100)}%`
              : '-',
        },
        {
          title: 'Mean transcription time % (OpenAI)',
          value:
            metrics.regional_transcription_metrics.openai !== undefined
              ? `${roundValue(metrics.regional_transcription_metrics.openai * 100)}%`
              : '-',
        },
        {
          title: 'Mean generation time % (southcentralus)',
          value:
            metrics.regional_generation_metrics.southcentralus !== undefined
              ? `${roundValue(metrics.regional_generation_metrics.southcentralus * 100)}%`
              : '-',
        },
        {
          title: 'Mean generation time % (swedencentral)',
          value:
            metrics.regional_generation_metrics.swedencentral !== undefined
              ? `${roundValue(metrics.regional_generation_metrics.swedencentral * 100)}%`
              : '-',
        },
        {
          title: 'Mean generation time % (eastus)',
          value:
            metrics.regional_generation_metrics.eastus !== undefined
              ? `${roundValue(metrics.regional_generation_metrics.eastus * 100)}%`
              : '-',
        },
        {
          title: 'Mean generation time % (westus)',
          value:
            metrics.regional_generation_metrics.westus !== undefined
              ? `${roundValue(metrics.regional_generation_metrics.westus * 100)}%`
              : '-',
        },
        {
          title: 'Mean generation time % (westus5)',
          value:
            metrics.regional_generation_metrics.westus5 !== undefined
              ? `${roundValue(metrics.regional_generation_metrics.westus5 * 100)}%`
              : '-',
        },
        {
          title: 'DynamoDB reads used',
          value: roundValue(metrics.dynamodb_reads_used) ?? '-',
        },
      ]
    : [];

  // Group the monitoring data by categories
  const groupedMonitoringData = {};

  monitoringData.forEach((card) => {
    const category =
      Object.keys(metricCategories).find((category) =>
        metricCategories[category].includes(card.title)
      ) || 'Other Metrics';

    if (!groupedMonitoringData[category]) {
      groupedMonitoringData[category] = [];
    }
    groupedMonitoringData[category].push(card);
  });

  // Function to get status color
  const getStatusColor = (status) => {
    if (status === 'Transcribing' || status === 'Generating') {
      return 'orange';
    } else if (status === 'Sent' || status === 'sent') {
      return 'green';
    } else if (status === 'Error') {
      return 'red';
    } else {
      return 'black';
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <MenuButton onClick={() => window.location.reload()}>
        <RefreshRoundedIcon fontSize="large" />
      </MenuButton>

      {DoctorID && ConsultationID ? (
        <>
          <Typography component="h2" variant="h6" sx={{ mb: 2, mt: { xs: 6, md: 2 } }}>
            Consultation Details
          </Typography>
          {apiLoading ? (
            <Typography>Loading...</Typography>
          ) : apiError ? (
            <Typography color="error">Error fetching data</Typography>
          ) : apiData ? (
            <>
              <Card variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">Doctor ID: {DoctorID}</Typography>
                  <Typography variant="h6">Consultation ID: {ConsultationID}</Typography>
                  <Typography variant="h6">
                    Status:{' '}
                    <span style={{ color: getStatusColor(apiData.status) }}>
                      {apiData.status}
                    </span>
                  </Typography>
                </CardContent>
              </Card>

              {/* Display other consultation details */}
              <div style={{ height: 400, width: '100%' }}>
                <CustomizedDataGrid rawData={[apiData]} loading={apiLoading} />
              </div>
            </>
          ) : (
            <Typography>No consultation data available.</Typography>
          )}
        </>
      ) : (
        <>
          {/* Monitoring Overview */}
          <Typography component="h2" variant="h6" sx={{ mb: 2, mt: { xs: 6, md: 2 } }}>
            Monitoring Overview
          </Typography>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">Error fetching data</Typography>
          ) : (
            <>
              {Object.keys(groupedMonitoringData).map((category, idx) => (
                <React.Fragment key={idx}>
                  <Typography component="h3" variant="h6" sx={{ mb: 2, mt: 2 }}>
                    {category}
                  </Typography>
                  <Grid container spacing={2} columns={12}>
                    {groupedMonitoringData[category].map((card, index) => (
                      <Grid item xs={12} sm={6} lg={3} key={index}>
                        <StatCard title={card.title} value={card.value.toString()} />
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              ))}
            </>
          )}

          {/* Main Table */}
          <Typography component="h2" variant="h6" sx={{ mb: 2, mt: 2 }}>
            Main Table
          </Typography>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12} md={12} lg={12}>
              <CustomizedDataGrid rawData={rawData} loading={loading} />
            </Grid>
          </Grid>

          {/* Monitoring Charts */}
          <Typography component="h2" variant="h6" sx={{ mb: 2, mt: 2 }}>
            Monitoring Charts
          </Typography>
          <Grid container spacing={2} columns={12}>
            {/*<Grid item xs={12} md={12} lg={9}>
              <SessionsChart />
            </Grid>*/}
            <Grid item xs={12} lg={3}>
              <ChartUserByCountry
                templateOne={metrics?.total_template_one ?? 0}
                templateTwo={metrics?.total_template_two ?? 0}
                templateThree={metrics?.total_template_three ?? 0}
                templateFour={metrics?.total_template_four ?? 0}
                consultationsToday={metrics?.consultations_today ?? 0}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
