import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from './internals/components/Copyright';
import ChartUserByCountry from './components/ChartUserByTemplate';
import CustomizedDataGrid from './components/UserTable';
import PageViewsBarChart from './components/NewUsersBarChart';
import SessionsChart from './components/TotalUsersLineChart';
import StatCard from './components/UserMetricCards';
import useAuth from '../../useAuth';

export default function UsersPage() {
  useAuth(); // This will redirect if not authenticated
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    // Fetch API data with API key
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://g2qfbf14j9.execute-api.af-south-1.amazonaws.com/nora/user-table',
          {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY, // API key
            },
          }
        );
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };

    fetchData();
  }, []);

  // Calculate Total Users
  const totalUsers = apiData ? apiData.length : 'Loading...';

  // Calculate other metrics if needed
  const totalPremiumUsers = apiData
    ? apiData.filter((user) => user.subscriptionLevel === 'Premium').length
    : 'Loading...';

  const totalFreeTrialUsers = apiData
    ? apiData.filter((user) => user.subscriptionLevel === 'FreeTrial').length
    : 'Loading...';

  // Placeholder data for charts and other metrics
  const data = [
    {
      title: 'Total Users',
      value: totalUsers,
      interval: 'Last 30 days',
      trend: 'up',
      data: [
        200, 240, 220, 260, 240, 380, 300, 240, 280, 240, 300, 340, 320, 360, 340, 380,
        360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
      ],
    },
    {
      title: 'Premium Users',
      value: totalPremiumUsers,
      interval: 'Last 30 days',
      trend: 'up',
      data: [
        1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600, 820,
        780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300, 220,
      ],
    },
    {
      title: 'Free Trial Users',
      value: totalFreeTrialUsers,
      interval: 'Last 30 days',
      trend: 'neutral',
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'Monthly Active Users',
      value: '-', // Replace with actual calculation if available
      interval: 'Last 30 days',
      trend: 'up',
      data: [
        500, 450, 480, 500, 520, 550, 580, 600, 620, 640, 660, 680, 700, 720, 740, 760,
        780, 800, 820, 840, 860, 880, 900, 920, 940, 960, 980, 1000, 1020, 1040,
      ],
    },
    {
      title: 'Weekly Active Users',
      value: apiData ? apiData.weekly_active_users : 'Loading...',
      interval: 'Last 30 days',
      trend: 'up', // Adjust based on trend logic
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'Daily Active Users',
      value: apiData ? apiData.daily_active_users : 'Loading...',
      interval: 'Last 30 days',
      trend: 'up', // Adjust based on trend logic
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'New Monthly Users',
      value: '-',
      interval: 'Last 30 days',
      trend: 'neutral',
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
    {
      title: 'New Monthly Paid Users',
      value: '-',
      interval: 'Last 30 days',
      trend: 'up',
      data: [
        500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
        520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
      ],
    },
  ];

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* Cards and charts */}
      <Typography component="h2" variant="h6" sx={{ mb: 2, mt: { xs: 6, md: 2 } }}>
        Users Overview
      </Typography>
      <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <SessionsChart totalUsers={totalUsers} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <PageViewsBarChart />
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        User Table
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid size={{ xs: 12, md: 12, lg: 9 }}>
          <CustomizedDataGrid apiData={apiData} />
        </Grid>
        <Grid size={{ xs: 12, lg: 3 }}>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
            <ChartUserByCountry />
          </Stack>
        </Grid>
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
