import React, { useEffect, useState } from 'react';
import axios from 'axios'; // For API requests
import { Line } from 'react-chartjs-2'; // For plotting the chart
import { Chart, registerables } from 'chart.js'; // Chart.js registration
import 'chartjs-adapter-date-fns'; // Import date-fns adapter for time-based charts
import StatCard from './components/MonitoringMetricCards'; // Assuming StatCard is located at ../components
import { Box, Grid, Typography } from '@mui/material';
import useAuth from '../../useAuth'; // Import useAuth hook

Chart.register(...registerables); // Register all necessary Chart.js components

function NotificationPage() {
  useAuth(); // This will redirect the user to login if not authenticated

  const [metrics, setMetrics] = useState({
    consultations: 0,
    consultationsErrored: 0,
    consultationsFailed: 0,
    executionTimePerMinAudio: 0,
  });
  const [userData, setUserData] = useState({});
  const [consecutiveUsers, setConsecutiveUsers] = useState([]);

  // Fetch data from the API
  useEffect(() => {
    axios
      .get('https://g2qfbf14j9.execute-api.af-south-1.amazonaws.com/nora/dashboard') // Your API URL
      .then((response) => {
        const data = response.data;

        setMetrics({
          consultations: data.yesterday_stats.consultations,
          consultationsErrored: data.yesterday_stats.consultations_errored,
          consultationsFailed: data.yesterday_stats.consultations_failed,
          executionTimePerMinAudio: data.yesterday_stats.execution_time_per_min_audio,
        });
        setUserData(data.user_data);
        setConsecutiveUsers(data.consecutive_users);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Function to format and prepare the user data for Chart.js
  const getUserActivityChartData = (userData) => {
    const labels = []; // Store all dates
    const datasets = []; // Store the user activity datasets
    const userIds = Object.keys(userData); // Extract unique user IDs for the Y-axis

    userIds.forEach((user, idx) => {
      const userDates = userData[user].map((date) => {
        const year = Math.floor(date / 10000);
        const month = Math.floor((date % 10000) / 100) - 1; // Month is zero-based in JS
        const day = date % 100;
        return new Date(year, month, day);
      });

      userDates.sort((a, b) => a - b); // Sort the dates

      // Add the user's activity dataset
      datasets.push({
        label: user, // Use the full user ID for identification in tooltips
        data: userDates.map((date) => ({ x: date, y: idx + 1 })), // Position on Y-axis by index
        borderColor: `hsl(${(idx * 40) % 360}, 70%, 50%)`,
        fill: false,
        tension: 0.1,
        pointRadius: 5,
      });

      // Add the dates to the labels array (unique)
      userDates.forEach((date) => {
        const dateStr = date.toISOString().split('T')[0];
        if (!labels.includes(dateStr)) {
          labels.push(dateStr);
        }
      });
    });

    return {
      labels: labels.sort(), // Ensure the labels (dates) are sorted
      datasets,
    };
  };

  return (
    <Box sx={{ p: 3, width: '100%', maxWidth: '100%' }}> {/* Updated Box to take full width */}
      <Typography variant="h4" gutterBottom>
        Nora Dashboard
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Yesterday's Metrics
      </Typography>

      {/* Display the Stat Cards */}
      <Grid container spacing={4} mb={4} sx={{ width: '100%' }}> {/* Added more space between sections */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard title="Consultations Yesterday" value={metrics.consultations.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard title="Consultations Errored Yesterday" value={metrics.consultationsErrored.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard title="Consultations Failed Yesterday" value={metrics.consultationsFailed.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard title="Execution Time Per Min Audio Yesterday" value={`${(metrics.executionTimePerMinAudio * 100).toFixed(2)}%`} />
        </Grid>
      </Grid>

      {/* Display the chart for user activity */}
      <Typography variant="h5" gutterBottom>
        User Activity Chart
      </Typography>
      <Box sx={{ width: '100%', mb: 4 }}> {/* Ensure chart takes full width */}
        <Line
          data={getUserActivityChartData(userData)}
          options={{
            responsive: true,
            maintainAspectRatio: false, // Optional: allows the chart to adjust dynamically
            scales: {
              x: {
                type: 'time', // Specify time scale for x-axis
                time: {
                  unit: 'day', // Display data per day
                  tooltipFormat: 'yyyy-MM-dd', // Format for tooltips
                },
                title: {
                  display: true,
                  text: 'Date',
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'DoctorID (User ID)',
                },
                ticks: {
                  callback: (value) => {
                    const userIds = Object.keys(userData);
                    if (userIds[value - 1]) {
                      return userIds[value - 1]; // Return the full user ID
                    }
                    return value;
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false, // Hide the legend since we're now showing user IDs on the Y-axis
              },
            },
          }}
          style={{ width: '100%' }} // Ensure chart takes full width
        />
      </Box>

      {/* Display the list of consecutive users */}
      <Box mt={3} sx={{ width: '100%' }}> {/* Ensure list takes full width */}
        <Typography variant="h6">
          Users with Three Consecutive Days of Activity:
        </Typography>
        {consecutiveUsers.length > 0 ? (
          <ul>
            {consecutiveUsers.map((user) => (
              <li key={user}>{user}</li> // Display full user ID
            ))}
          </ul>
        ) : (
          <Typography>No users found with consecutive activity.</Typography>
        )}
      </Box>
    </Box>
  );
}

export default NotificationPage;