// PerformancePage.js
import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import MenuButton from '../../components/MenuButton';
import Copyright from './internals/components/Copyright';
import MonthlyConsultsBarChart from './components/MonthlyConsultsBarChart';
import DailyConsultsChart from './components/DailyConsultsLineChart';
import PageViewsBarChart from './components/ExecutionTimeBarChart';
import SessionsChart from './components/ExecutionVsAudioLineChart';
import StatCard from './components/PerformanceMetricCards';
import useAuth from '../../useAuth';

export default function PerformancePage() {
  useAuth();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      'https://g2qfbf14j9.execute-api.af-south-1.amazonaws.com/nora/usage-and-performance',
      {
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_KEY}`,
        },
      }
    )
      .then((response) => response.json())
      .then((apiData) => {
        // Sort the data by date in ascending order
        const sortedData = apiData.sort((a, b) => a.date - b.date);
        setData(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return <MainGrid data={data} loading={loading} />;
}

function MainGrid({ data, loading }) {
  useAuth();
  if (loading) {
    return (
      <Box
        sx={{
          width: '100%',
          maxWidth: { sm: '100%', md: '1700px' },
          textAlign: 'center',
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          sx={{ mb: 2, mt: { xs: 6, md: 2 } }}
        >
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          maxWidth: { sm: '100%', md: '1700px' },
          textAlign: 'center',
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          sx={{ mb: 2, mt: { xs: 6, md: 2 } }}
        >
          No data available.
        </Typography>
      </Box>
    );
  }

  // Data is already sorted in ascending order by date
  // Extract dates for x-axis labels
  const dates = data.map((day) => {
    const dateStr = day.date.toString(); // e.g., '20241004'
    const dateObj = new Date(
      parseInt(dateStr.substring(0, 4)), // year
      parseInt(dateStr.substring(4, 6)) - 1, // month (0-based)
      parseInt(dateStr.substring(6, 8)) // day
    );
    return dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }); // e.g., 'Oct 4'
  });

  // Helper function to create card data
  function getCardData(fieldName, title, options = {}) {
    const fieldIsPercentageValue = options.multiplyBy100AndRound;

    // Prepare data array including all days, and apply multiply by 100 if needed
    const dataArray = data.map((day) => {
      let value = day[fieldName];
      if (value !== undefined && value !== null) {
        if (fieldIsPercentageValue) {
          value = value * 100;
        }
        return parseFloat(value.toFixed(2));
      } else {
        return 0;
      }
    });

    const currentValue = dataArray[dataArray.length - 1];

    let displayValue = 'N/A';
    if (currentValue !== null && currentValue !== undefined) {
      if (options.appendPercentage) {
        displayValue = currentValue.toFixed(2).toString() + '%';
      } else if (options.largeNumberFormat) {
        // For large numbers, format with commas
        displayValue = currentValue
          .toLocaleString(undefined, { maximumFractionDigits: 2 })
          .toString();
      } else {
        displayValue = currentValue.toFixed(2).toString();
      }
    }

    return {
      title: title,
      value: displayValue,
      interval: 'Today',
      data: dataArray,
      dates: dates,
    };
  }

  // Define metric categories in the desired order
  const orderedMetricCategories = [
    'Consultation Metrics',
    'Audio Metrics',
    'Execution Metrics',
    'Transcription Metrics',
    'Generation Metrics',
    'Template Metrics',
    'Token Metrics',
    'OpenAI Metrics',
    'Source Metrics',
  ];

  // Map metrics to their categories
  const metricCategories = {
    'consultations_today': 'Consultation Metrics',
    'consultations_successful': 'Consultation Metrics',
    'consultations_failed': 'Consultation Metrics',
    'consultations_errored': 'Consultation Metrics',
    'consultations_regen_count': 'Consultation Metrics',
    'average_regens': 'Consultation Metrics',
    'consultation_success_rate': 'Consultation Metrics',
    'consultation_correction_success_rate': 'Consultation Metrics',

    'total_audio_length': 'Audio Metrics',
    'mean_audio_length': 'Audio Metrics',

    'total_execution_time_day': 'Execution Metrics',
    'execution_time_per_min_audio': 'Execution Metrics',

    'mean_time_to_transcribe': 'Transcription Metrics',
    'mean_time_to_transcribe_percentage_northcentralus': 'Transcription Metrics',
    'mean_time_to_transcribe_percentage_norwayeast': 'Transcription Metrics',
    'mean_time_to_transcribe_percentage_southindia': 'Transcription Metrics',
    'mean_time_to_transcribe_percentage_westeurope': 'Transcription Metrics',
    'mean_time_to_transcribe_percentage_swedencentral': 'Transcription Metrics',
    'mean_time_to_transcribe_percentage_openai': 'Transcription Metrics',

    'mean_generation_time': 'Generation Metrics',
    'mean_time_to_generate_percentage_eastus': 'Generation Metrics',
    'mean_time_to_generate_percentage_westus': 'Generation Metrics',
    'mean_time_to_generate_percentage_southcentralus': 'Generation Metrics',
    'mean_time_to_generate_percentage_swedencentral': 'Generation Metrics',
    'mean_time_to_generate_percentage_westus5': 'Generation Metrics',

    'total_template_one': 'Template Metrics',
    'total_template_two': 'Template Metrics',
    'total_template_three': 'Template Metrics',
    'total_template_four': 'Template Metrics',

    'input_tokens_total': 'Token Metrics',
    'output_tokens_total': 'Token Metrics',
    'mean_input_tokens': 'Token Metrics',
    'mean_output_tokens': 'Token Metrics',
    'total_input_token_cost': 'Token Metrics',
    'total_output_token_cost': 'Token Metrics',

    'openai_total_requests': 'OpenAI Metrics',
    'openai_total_seconds': 'OpenAI Metrics',
    'total_openai_whisper_costs': 'OpenAI Metrics',

    'total_source_HBC': 'Source Metrics',
    'total_source_hbc': 'Source Metrics',
    'total_source_other': 'Source Metrics',
  };

  const currentDayData = data[data.length - 1]; // Latest day data

  const apiKeys = Object.keys(currentDayData).filter((key) => key !== 'date');

  const metricsToMultiplyBy100 = [
    'mean_time_to_generate_percentage_eastus',
    'mean_time_to_generate_percentage_westus',
    'mean_time_to_transcribe_percentage_northcentralus',
    'mean_time_to_transcribe_percentage_norwayeast',
    'mean_time_to_transcribe_percentage_southindia',
    'mean_time_to_transcribe_percentage_westeurope',
    'mean_time_to_generate_percentage_southcentralus',
    'mean_time_to_transcribe_percentage_swedencentral',
    'mean_time_to_generate_percentage_swedencentral',
    'execution_time_per_min_audio',
    'mean_time_to_generate_percentage_westus5',
  ];

  const groupedCardsData = {};

  apiKeys.forEach((key) => {
    const title = key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (c) => c.toUpperCase());
      const isPercentage =
      key.includes('percentage') ||
      key.includes('rate') ||
      key === 'execution_time_per_min_audio'; // Added this condition
    const multiplyBy100AndRound = metricsToMultiplyBy100.includes(key);
    const isLargeNumber =
      currentDayData[key] !== null &&
      currentDayData[key] !== undefined &&
      currentDayData[key] > 1000;

    const card = getCardData(key, title, {
      appendPercentage: isPercentage,
      largeNumberFormat: isLargeNumber,
      multiplyBy100AndRound: multiplyBy100AndRound,
    });

    const category = metricCategories[key] || 'Other Metrics';

    if (!groupedCardsData[category]) {
      groupedCardsData[category] = [];
    }
    groupedCardsData[category].push(card);
  });

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <MenuButton onClick={() => window.location.reload()}>
        <RefreshRoundedIcon fontSize="large" />
      </MenuButton>
      {/* Cards */}
      <Typography
        component="h2"
        variant="h6"
        sx={{ mb: 2, mt: { xs: 6, md: 2 } }}
      >
        Usage & Performance Overview
      </Typography>
      {orderedMetricCategories.map((category, idx) => (
        groupedCardsData[category] ? (
          <React.Fragment key={idx}>
            <Typography component="h3" variant="h6" sx={{ mb: 2, mt: 2 }}>
              {category}
            </Typography>
            <Grid container spacing={2} columns={12}>
              {groupedCardsData[category].map((card, index) => (
                <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
                  <StatCard {...card} />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ) : null
      ))}
      {/* Display any other categories not specified in the order */}
      {Object.keys(groupedCardsData)
        .filter((category) => !orderedMetricCategories.includes(category))
        .map((category, idx) => (
          <React.Fragment key={`other-${idx}`}>
            <Typography component="h3" variant="h6" sx={{ mb: 2, mt: 2 }}>
              {category}
            </Typography>
            <Grid container spacing={2} columns={12}>
              {groupedCardsData[category].map((card, index) => (
                <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
                  <StatCard {...card} />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      <Typography component="h2" variant="h6" sx={{ mb: 2, mt: 2 }}>
        Usage & Performance Charts
      </Typography>
      <Grid container spacing={2} columns={12}>
        {/* Include other charts here as needed */}
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <DailyConsultsChart
            todayConsultations={
              currentDayData.consultations_today?.toString() || 'N/A'
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <MonthlyConsultsBarChart
            monthlyConsultations={
              currentDayData.consultations_this_month?.toString() || 'N/A'
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <SessionsChart />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <PageViewsBarChart />
        </Grid>
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
