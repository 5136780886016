// UsagePage.js
import React, { useEffect, useState } from 'react';
import { TextField, Button, Card, CardContent, Typography, Grid, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import useAuth from '../../useAuth';

export default function UsagePage() {
  useAuth();

  const [consultations, setConsultations] = useState([]);
  const [selectedConsultationID, setSelectedConsultationID] = useState(null);
  const [consultationDetails, setConsultationDetails] = useState(null);
  const [showTranscriptNotes, setShowTranscriptNotes] = useState(false);
  const [transcriptText, setTranscriptText] = useState('');
  const [notesText, setNotesText] = useState('');

  // Fetch consultations from the Main Table in Monitoring Page
  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const response = await axios.get(
          'https://g2qfbf14j9.execute-api.af-south-1.amazonaws.com/nora/monitor',
          {
            headers: {
              'x-api-key': `${process.env.REACT_APP_API_KEY}`,
            },
          }
        );
        const rawData = response.data.raw_data;
        setConsultations(rawData);
      } catch (error) {
        console.error('Error fetching consultations:', error);
      }
    };

    fetchConsultations();
  }, []);

  // Fetch consultation details when a ConsultationID is selected
  useEffect(() => {
    const fetchConsultationDetails = async () => {
      if (selectedConsultationID) {
        try {
          const selectedConsultation = consultations.find(
            (consultation) => consultation.ConsultationID === selectedConsultationID
          );
          setConsultationDetails(selectedConsultation);
        } catch (error) {
          console.error('Error fetching consultation details:', error);
        }
      } else {
        setConsultationDetails(null);
        setShowTranscriptNotes(false);
      }
    };

    fetchConsultationDetails();
  }, [selectedConsultationID, consultations]);

  // Handle Download Audio button click
  const handleDownloadAudio = () => {
    if (consultationDetails) {
      // Replace this with the actual URL or logic to download the audio file
      const audioUrl = `https://your-audio-bucket-url/${consultationDetails.DoctorID}_${consultationDetails.ConsultationID}.mp3`;

      // Trigger download
      const link = document.createElement('a');
      link.href = audioUrl;
      link.download = `${consultationDetails.ConsultationID}.mp3`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Handle View Transcript & Notes button click
  const handleViewTranscriptNotes = async () => {
    if (consultationDetails) {
      try {
        // Fetch Transcript
        const transcriptResponse = await axios.get(
          `https://your-transcript-bucket-url/${consultationDetails.transcript}`
        );
        setTranscriptText(transcriptResponse.data);

        // Fetch Notes
        const notesResponse = await axios.get(
          `https://your-notes-bucket-url/${consultationDetails.notes}`
        );
        setNotesText(JSON.stringify(notesResponse.data, null, 2));

        setShowTranscriptNotes(true);
      } catch (error) {
        console.error('Error fetching transcript or notes:', error);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', padding: 2 }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Consultation Lookup
      </Typography>
      <Autocomplete
        options={consultations.map((option) => option.ConsultationID)}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Consultation ID" variant="outlined" />}
        onChange={(event, value) => setSelectedConsultationID(value)}
      />

      {consultationDetails && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Consultation Details</Typography>
          <Grid container spacing={2}>
            {Object.entries(consultationDetails).map(([key, value]) => (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <Typography variant="body1">
                  <strong>{key.replace(/_/g, ' ')}:</strong> {value.toString()}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleDownloadAudio} sx={{ mr: 2 }}>
              Download Audio
            </Button>
            <Button variant="contained" color="secondary" onClick={handleViewTranscriptNotes}>
              View Transcript & Notes
            </Button>
          </Box>
        </Box>
      )}

      {showTranscriptNotes && (
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Transcript
                </Typography>
                <Typography variant="body2" component="pre">
                  {transcriptText}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <Typography variant="body2" component="pre">
                  {notesText}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
