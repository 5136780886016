// MainTable.js

import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';

export default function CustomizedDataGrid({ rawData, loading }) {
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    if (rawData) {
      // Ensure each row has a unique 'id' field required by DataGrid
      const rowsWithId = rawData.map((item, index) => ({
        id: index + 1,
        ...item,
      }));
      setRows(rowsWithId);
    }
  }, [rawData]);

  // Define the columns based on rawData's keys
  const columns =
    rawData && rawData.length > 0
      ? Object.keys(rawData[0]).map((key) => ({
          field: key,
          headerName: key.replace(/_/g, ' ').toUpperCase(),
          flex: 1,
          minWidth: 150, // Adjust the minWidth as needed
        }))
      : [];

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        disableColumnResize={false}
        density="compact"
        loading={loading}
        checkboxSelection
        sx={{
          '& .MuiDataGrid-columnHeader': {
            whiteSpace: 'normal',
            lineHeight: '1.2',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            overflow: 'visible',
            whiteSpace: 'normal',
            textOverflow: 'clip',
          },   
          '& .MuiDataGrid-cell': {
            fontSize: '0.8rem',
          },
        }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small',
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small',
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
