import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { columns } from '../internals/data/gridData';

export default function CustomizedDataGrid({ apiData }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (apiData) {
      const formattedRows = apiData.map((item, index) => ({
        id: index + 1,
        DoctorID: item.DoctorID,
        subscriptionLevel: item.subscriptionLevel,
        Vendor: item.vendor,
        FreeTrial_start_date: item.FreeTrial_start_date || 'N/A',
        FreeTrial_end_date: item.FreeTrial_end_date || 'N/A',
        usage_counter: item.usage_counter || 0,
        daily_usage: item.daily_usage ? [item.daily_usage] : [],
      }));

      setRows(formattedRows);
    }
  }, [apiData]);

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      rows={rows}
      columns={columns}
      loading={!apiData}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 20 } },
      }}
      pageSizeOptions={[10, 20, 50]}
      disableColumnResize
      density="compact"
      slotProps={{
        filterPanel: {
          filterFormProps: {
            logicOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
              },
            },
          },
        },
      }}
    />
  );
}

CustomizedDataGrid.propTypes = {
  apiData: PropTypes.array,
};
